import React from 'react'

interface PaginationSlugParams {
  slug: string
  currentPage: number
  isCategoryPath?: boolean
}

export const usePaginationSlug = ({ slug, currentPage, isCategoryPath }: PaginationSlugParams) => {
  const homeSlug = !isCategoryPath ? `/${slug}` : `/${slug}/category`
  const pageSlug = !isCategoryPath ? `/${slug}/page-` : `/${homeSlug}/page-`
  const prevPage =
    currentPage - 1 === 1 ? homeSlug : pageSlug + (currentPage - 1)
  const nextPage = pageSlug + (currentPage + 1)

  return {
    homeSlug,
    pageSlug,
    prevPage,
    nextPage,
  }
}